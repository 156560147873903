<template>
  <div class="login-container">
    <h2>{{ $t('pageLogin.pageTitle') }}</h2>
    <form @submit.prevent="checkInput">
      <div class="form-group">
        <label for="email">{{ $t('pageLogin.emailLabel') }}</label>
        <input type="email" id="email" v-model="emailInput" required>
      </div>
      <div class="form-group">
        <label for="password">{{ $t('pageLogin.passwordLabel') }}</label>
        <input type="password" id="password" v-model="passwordInput" required>
      </div>
      <div class="liens">
        <button type="submit">{{ $t('pageLogin.loginButton') }}</button>
      </div>
      <div class="liens margin50">
        <router-link to="/forgot-password">{{ $t('pageLogin.forgotPasswordLink') }}</router-link>
        <router-link to="/register">{{ $t('pageLogin.createAccountLink') }}</router-link>
      </div>
    </form>
    <v-row justify="center">
        <v-dialog v-model="timerDialog" persistent max-width="300px">
            <v-card>
				<v-card-title>
					<span class="text-h5">{{ $t('pageLogin.activeSessionTitle') }}</span>
				</v-card-title>
				<v-card-text>
					<p>{{ $t('pageLogin.activeSessionMessage') }}</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeTimerDialog">
					{{ $t('pageLogin.closeButton') }}
					</v-btn>
				</v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</div>

</template>

<script>
	export default {
		name: 'Login',
		data(){
			return {
				passwordInput: '',
				emailInput: '',
				codeInput: '',
				EmailExistenceLength: 0,
				passwordDatabase: '',
				color: 'success',
				user: {
					id: '',
					username: '',
					email: '',
					admin: 'true'
				},
				timerDialog: false,
				timer: 0, // Timer in milliseconds
				remainingTime: 0,
				intervalId: null,
				errors: [],
			}
		},
		mounted(){
			this.checkIfUserActive()
		},
		methods: {
			changeLanguage(lang) {
				this.$i18n.locale = lang;
			},
			async checkInput(){
				this.errors = []
				if(!this.passwordInput || !this.emailInput){
					this.errors.push(" please enter all fields")
					window.alert(this.errors)
					return
				}else{
					const emailChecked = await Promise.all([this.CheckEmailExistence()])
					if(emailChecked){
						if(this.EmailExistenceLength > 0){
								const PasswordTested = await this.TestPassword();
								if(PasswordTested == true){
									const SessionActive = await this.checkSessionActive();
									if(SessionActive == true){
										const UserLoaded = await Promise.all([this.LoadUser()])
										if (UserLoaded){
											const TokenGenerated = await Promise.all([this.generateToken()])
											if(TokenGenerated){
												this.setSessionActive();
												this.requestLocationConsent();
												this.$router.push({name:'tableau-de-bord'})
											}else{
												return
											}
										}else{
											this.errors.push(" Something went wrong.. Please retry")
											window.alert(this.errors)
											return
										}
									}else{
										this.timerDialog = true;
        								this.startTimer();
										return
									}
								}else{
									this.errors.push(" Wrong password")
									window.alert(this.errors)
									return
								}
						}else{
							this.errors.push(" User doesn't exist")
							window.alert(this.errors)
							return
						}
					}else{
						return
					}
				}
			},

			async CheckEmailExistence(){
				const email = encodeURIComponent(this.emailInput)
				const url = this.$api.getRESTApiUri() + `/check-email-existence/${email}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					this.EmailExistenceLength = data.length
				})
				.catch((error) => {
					console.log(error)
				});	
			},

			async TestPassword(){
				const email = this.emailInput
				const password = this.passwordInput
				const url = this.$api.getRESTApiUri() + `/get-password`
				return fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: email,
						password : password,
					}),
				})
                .then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					if(data.exists == true &&  data.passwordMatch == true){
						return true;
					}else{
						return false
					}
				})
				.catch((error) => {
					console.log(error)
				});	
			},

			async LoadUser(){
				const email = encodeURIComponent(this.emailInput);
				console.log(email)
				const url = this.$api.getRESTApiUri() + `/user-info/email/${email}`;
				return fetch(url)
				.then(res => res.text())
				.then(async (result) => {
					const data = JSON.parse(result);
					console.log('rrrrrrrrrr')
					console.log(data)
					this.user = {
						email: data.email,
						username: data.username,
						admin: data.admin,
					};
				})
				.catch(error => {
					console.log(error)
				});
			},

			//Dispenser l'utilisateur de se loger une nouvelle fois si son jeton correspond à celui de sa session active dans BDD
			async checkIfUserActive(){
				const sessionToken = localStorage.getItem('sessionToken')
				const user = JSON.parse(localStorage.getItem('user'))

				// Vérifiez si l'utilisateur existe avant de continuer
				if (!user || !user.email) {
					console.log("L'utilisateur ou l'email est manquant.");
					// Vous pouvez également rediriger l'utilisateur ou gérer ce cas comme nécessaire
					return;
				}

				const url = this.$api.getRESTApiUri() + `/check-session-token`
				return fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: user.email,
						jwt_session : sessionToken,
					}),
				})
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					if(data.message == 'Token is valide'){ // Remarque : utilisez '==' ou '===' pour la comparaison
						this.$router.push({name:'tableau-de-bord'})
					}else{
						// Gérer le cas où le token n'est pas valide
					}
					
				})
				.catch((error) => {
					console.log(error)
				}); 
			},

			// Quand l'utilisateur se log et qu'il s'est authentifié, checker si une session avec son email n'est pas déjà active
			async checkSessionActive(){
				const url = this.$api.getRESTApiUri() + `/check-user-activity`
				return fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: this.emailInput,
						token: 'no-token'
					}),
				})
                .then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					console.log(data.message)
					this.timer = data.timer;
					this.remainingTime = Math.ceil(this.timer / 1000);
					if(data.result === 'isSessionActive'){
						return false;
					}else if(data.result === 'noSession') {
						return true;
					}else if( data.result === 'isSessionInactive'){
						return true;
					}else{
						return false
					}
				})
				.catch((error) => {
					console.log(error)
				});	
			},

			async setSessionActive(){
				const url = this.$api.getRESTApiUri() + `/user/set-session`;
				return fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							user: this.user,
						}),
				})
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					localStorage.setItem('sessionToken', data.sessionToken)
				})
				.catch((error) => {
					console.log(error)
				});
			},

			async generateToken(){
				const url = this.$api.getRESTApiUri() + `/generate-token`;
				return fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							user: this.user,
						}),
				})
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					localStorage.setItem('user', JSON.stringify(data.user))
					localStorage.setItem('accessToken', data.accesToken)
					localStorage.setItem('refreshToken', data.refreshToken)
				});	
			},

			// Localisation avec API Google Maps
			requestLocationConsent() {
				if (process.env.NODE_ENV === 'development') {
					// Utiliser des données factices en développement
					this.locationSuccess({ coords: { latitude: 48.8566, longitude: 2.3522 } });
				} else if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError);
					
				} else {
					return
				}
			},

			locationSuccess(position) {
				// Traiter la position ici

				this.getCityFromCoordinates(position.coords.latitude, position.coords.longitude).then(city => console.log(city));
				this.InsertLocation(position.coords);
			},

			async getCityFromCoordinates(lat, lng) {
				const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=VOTRE_CLE_API`;

				try {
					const response = await fetch(url);
					const data = await response.json();

					if (data.status === "OK") {
						// Le résultat peut inclure plusieurs adresses. 
						// Vous pouvez choisir le premier résultat ou affiner selon vos besoins
						const addressComponents = data.results[0].address_components;
						const cityComponent = addressComponents.find(component =>
							component.types.includes("locality")
						);

						return cityComponent ? cityComponent.long_name : null;
					} else {
						console.error('Erreur du géocodage inversé', data.status);
						return null;
					}
				} catch (error) {
					console.error('Erreur lors de la récupération de la ville', error);
					return null;
				}
			},

			async InsertLocation(location){
				const url = this.$api.getRESTApiUri() + `/insert-location`
				return fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						localisation: location,
					}),
				})
                .then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
				})
				.catch((error) => {
					console.log(error)
				});	
			},

			locationError(error) {
				console.log('Error: ' + error.message);
			},
			startTimer() {
				this.intervalId = setInterval(() => {
					if (this.remainingTime > 0) {
					this.remainingTime -= 1;
					} else {
					this.stopTimer();
					// Take action after timer ends
					this.timerDialog = false;
					}
				}, 1000); // Update every second
			},
			stopTimer() {
				clearInterval(this.intervalId);
				this.timer = 0;
				this.remainingTime = 0;
			},
			closeTimerDialog() {
				this.stopTimer();
				this.timerDialog = false;
			},
		},
		beforeDestroy() {
			this.stopTimer();
		},
	}
</script>


<style scoped >
.login-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	
}

.login-container form {
	width: 500px;
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.login-container h2 {
	font-family: 'Roboto', sans-serif;
    font-size: 36px;
    font-weight: 500;
	color: var(--bleu);
	margin-bottom: 20px;
}

.form-group {
	margin-bottom: 20px;	
}

.login-container label {
	display: block;
	color: var(--gris);
	margin-bottom: 5px;
}

.login-container input[type="email"],
	input[type="password"] {
	width: 100%;
	padding: 10px;
	border: 1px solid var(--grisclair);
	border-radius: 5px;
}

.login-container button {
	background-color: var(--vertfonce);
	color: white !important;
	border: none;
	border-radius: 20px;
	padding: 10px 20px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.login-container button:hover {
	background-color: var(--turquoise);
}

.liens{
	display: flex;
	justify-content: space-around;
	align-items: end;
}

.liens a{
	color: var(--bleu);
	text-decoration: none;
}

.liens a:hover{
	font-weight: 500;
}

.margin50{
	margin-top: 50px;
}
</style>
